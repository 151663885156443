@media(max-width: 1024px) {
  $rootPadding: 8px;

  #root {
    padding: $rootPadding;

    > div {
      width: 100%;
      margin: auto 4px auto 4px;
    }
  }

  button {
    font-size: 18pt;
  }

  #questions-container {
    > * + * {
      margin-top: 24px;
    }

    width: 100%;
    max-width: 100%;

    .question {
      padding: 12px;

      min-width: 0;
      max-width: 100%;

      margin: 0;

      .wording {
        min-height: 200px;
        font-size: 24pt;
      }
    }
  }

  #footer {
    padding-left: $rootPadding;
  }
}
